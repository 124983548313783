import React, { useContext } from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faPencilRuler,
  faToolbox,
  faUserLock,
  faUserCheck,
  faCoins,
  faChartLine,
  faHotel,
  faQuestion,
  faLandmark,
  faBuilding,
  faCoffee,
  faGlassMartini,
  faDumbbell,
  faConciergeBell,
  faCouch,
  faUtensils,
  faUserGraduate,
  faBookOpen,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import IconCards from "../../../components/business/IconCards"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import { myContext } from "../../../ThemeContext"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function WirelessNetworks() {
  const CardsWithIcons = [
    {
      icon: <FontAwesomeIcon icon={faPencilRuler} />,
      title: "Complete design & implementation",
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} />,
      title: "Robust performance",
    },
    {
      icon: <FontAwesomeIcon icon={faToolbox} />,
      title: "Full maintenance support",
    },
    {
      icon: <FontAwesomeIcon icon={faUserLock} />,
      title: "Total control of access",
    },
    {
      icon: <FontAwesomeIcon icon={faUserCheck} />,
      title: "Full training on-site",
    },
    {
      icon: <FontAwesomeIcon icon={faCoins} />,
      title: "Create an income from your wireless network",
    },
  ]

  const data = useStaticQuery(graphql`
    query businessWirelessNetworks {
      laptopsAndTablets: file(
        relativePath: { eq: "services/AdobeStock_274709086.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      storage: file(
        relativePath: { eq: "services/AdobeStock_101536916.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Printers: file(
        relativePath: { eq: "services/AdobeStock_255540819.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      NetworkSecurity: file(
        relativePath: { eq: "services/AdobeStock_46647398.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      WirelessNetworks: file(
        relativePath: { eq: "services/AdobeStock_250877262.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_207885558.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Laptops & Tablets",
      description: "iMacs, Macbooks, Google Chromebooks and PCs",
      image: data.laptopsAndTablets.childImageSharp.fixed,
    },
    {
      title: "Storage",
      description: "Backup, NAS, Airport Time Capsule",
      image: data.storage.childImageSharp.fixed,
    },
    {
      title: "IT Networks",
      description: "Servers, Storage, Cabling, Switches and Web Filtering",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Printers",
      description:
        "Mono Laser Printers, Colour Laser Printers, Multifunction Printers, Inkjet Printer",
      image: data.Printers.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
    {
      title: "Wireless Networks",
      description:
        "Access Points, Outdoor Access Points, Wireless Controllers, Routers, Fibre Optic",
      image: data.WirelessNetworks.childImageSharp.fixed,
    },
    {
      title: "Network Security",
      description: "Web Filtering, Antivirus, Server Racks",
      image: data.NetworkSecurity.childImageSharp.fixed,
    },
  ]

  const FurtherBenefits = styled.div`
    margin: 0rem 0 2rem 0;
    h3 {
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      padding-left: 0;
      li {
        font-size: 16px;
        padding-left: 25px;
        list-style: none;
        position: relative;
        padding-bottom: 10px;
        svg {
          position: absolute;
          left: 0px;
          top: 5px;
          color: #27ae60;
        }
      }
    }
  `

  const Center = styled.div`
    display: flex;
    justify-content: center;
  `

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Wireless Networks Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Wireless Networks"
              description={
                <p>
                  Deploying wireless networks in environments such as
                  manufacturing sites, hospitals, campuses, corporate offices
                  and large facilities is a far cry from setting up a small
                  wireless network at home or in a small office. Add to this
                  complex technologies such as laser and microwave for multiple
                  building links and line of sight installations and your IT
                  department are probably in new territory.
                </p>
              }
            />
          </Col>
        </Row>
        <FurtherBenefits>
          <Row>
            <Col>
              <h3>
                Latest state of the art wireless technology, to enhance your
                business environment
              </h3>
              <Row>
                <Col>
                  <Center>
                    <ul>
                      {[
                        "How about saving valuable budget normally spent on cabling costs, engineering and labour?",
                        "Set up a new office or laboratory quickly, move a whole room of IT equipment easily",
                        "Remove the need for extensive patching and cabling",
                        "Take advantage of the performance with remote cloud computing",
                      ].map(item => (
                        <li>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Center>
                </Col>
              </Row>
              <p>
                Whether the implementation is completely a wireless one or a
                wireless extension to an existing wired network the principles
                remain the same. A good wireless network has high transmission
                speeds, a robust design that minimises points of failure and an
                effective cell and channel structure, whilst providing you with
                far greater security than that of a traditional wired network.
              </p>
              <p>
                The way in which wireless network users can move seamlessly
                between different radio cells is a key factor in delivering
                network quality as is taking into account other factors that
                affect signal saturation such as people, equipment, furniture
                and structural obstacles.
              </p>
              <p>
                Similarly the design must take into account the number of
                clients that require band-width, the demands on particular cells
                and the data traffic volume. All of these will determine the
                performance and a professional survey is a fundamental factor in
                ensuring an optimal design.
              </p>
            </Col>
          </Row>
        </FurtherBenefits>
      </Container>
      <Banner
        image={data.BannerImage.childImageSharp.fluid}
        title="Compulease will ensure you deploy a high performance, reliable and secure network with leading manufacturers such as Cisco, Ubiquiti and Aruba. We will cater to your bespoke requirements such as VPNs and remote storage and data redundancy."
        description="Talk to us now about the specialist skills we can offer your office environment"
        link={{
          text: "Call 01625 531 777",
          to: "/business/ContactUs/",
        }}
      />
      <IconCards
        title="Benefits of a Compulease wireless network"
        cards={CardsWithIcons}
      />
      <Container>
        {/* innovative income generator */}
        <WirelessHotspots />
        <PeaceOfMindGuarantee />
        <Services title="Solutions we provide" services={services} />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}

const WirelessHotspotsSection = styled.section`
  h2,
  h3,
  h6 {
    text-align: center;
  }
  h6 {
    color: ${props => props.secondaryBlue};
  }
  .list {
    margin: 0 0 2rem 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    padding-left: 0;
    li {
      font-size: 16px;
      padding-left: 25px;
      list-style: none;
      position: relative;
      padding-bottom: 10px;
      svg {
        font-size: 15px;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 5px;
        color: ${props => props.primaryBlue};
      }
    }
  }
`
const ListItem = styled.div`
  color: #6e6e6e;
  line-height: 48px;
  margin: 5px 0;
  font-size: 24px;
  svg {
    font-size: 34px;
    width: 34px;
    height: 34px;
    color: ${props => props.color};
    margin-right: 12px;
    min-width: 45px;
  }
`

function WirelessHotspots() {
  const theme = useContext(myContext)

  return (
    <WirelessHotspotsSection
      primaryBlue={theme.primaryBlue}
      secondaryBlue={theme.secondaryBlue}
    >
      <Row>
        <Col>
          <h6>Innovative income generator</h6>
          <h2>WiFi solutions for commercial profit</h2>
          <p>
            The Compulease™ WiFi solution is ideal for anyone wishing to provide
            their customers with instant wireless internet access. Modern and
            forward thinking, it is easily installed and has purposely been
            designed to be extremely easy for both the venue and the customers
            to use.
          </p>
          <p>
            The WiFi is installed independently to the company or business
            network using a dedicated private connection. Anyone attempting to
            connect to the wireless WiFi is automatically guided to a secure
            portal page on their browser, requested to provide login details,
            accept a fair use policy and then a secure authentication process
            occurs. The portal page can be customer branded with a company logo
            and other branding information.
          </p>
          <p>
            The solution is perfect for any venue, public or privately owned
            wishing to provide people with wireless internet access. The WiFi
            solution can be designed to work in a number of ways both
            technically and commercially. The WiFi venue can offer a “free vend”
            style of internet access in order to attract more people in, keep
            people on the premises longer and differentiate itself from the
            competition. Alternatively, the internet access can be on a tariff
            basis with “pay as you go” charges applied according to the amount
            of internet access time purchased. The WiFi owner retains up to 100%
            of the revenue, unlike many other WiFi solutions. Technically the
            solution has been designed so that the amount of bandwidth available
            per person can be managed quite easily and limits can be set to
            enforce a fair use policy.
          </p>
          <p>
            The WiFi has been designed so that no technical IT skills are
            required by venue staff in order to enable customer login accounts.
            The system has a simple user friendly account creation program for
            creation of customer internet sessions or it can be set up to work
            with a voucher style system.
          </p>
        </Col>
      </Row>
      <Row className="list">
        {[
          { text: "Hotels", icon: <FontAwesomeIcon icon={faHotel} /> },
          {
            text: "Conference Centres",
            icon: <FontAwesomeIcon icon={faLandmark} />,
          },
          { text: "Offices", icon: <FontAwesomeIcon icon={faBuilding} /> },
          { text: "Coffee Shops", icon: <FontAwesomeIcon icon={faCoffee} /> },
          {
            text: "Wine Bars",
            icon: <FontAwesomeIcon icon={faGlassMartini} />,
          },
          {
            text: "Leisure Facilities",
            icon: <FontAwesomeIcon icon={faDumbbell} />,
          },
          {
            text: "Reception Areas",
            icon: <FontAwesomeIcon icon={faConciergeBell} />,
          },
          {
            text: "Relaxation Areas",
            icon: <FontAwesomeIcon icon={faCouch} />,
          },
          { text: "Cafés", icon: <FontAwesomeIcon icon={faCoffee} /> },
          { text: "Pubs", icon: <FontAwesomeIcon icon={faUtensils} /> },
          { text: "Museums", icon: <FontAwesomeIcon icon={faLandmark} /> },
          {
            text: "Student Campuses",
            icon: <FontAwesomeIcon icon={faUserGraduate} />,
          },
          { text: "Libraries", icon: <FontAwesomeIcon icon={faBookOpen} /> },
          {
            text: "Places of Public Interest",
            icon: <FontAwesomeIcon icon={faMapPin} />,
          },
          {
            text: "Halls of Residence",
            icon: <FontAwesomeIcon icon={faBuilding} />,
          },
        ].map(item => {
          return (
            <Col sm="6" md="4">
              <ListItem color={theme.primaryBlue}>
                {item.icon ? item.icon : <FontAwesomeIcon icon={faQuestion} />}
                {item.text}
              </ListItem>
            </Col>
          )
        })}
      </Row>
    </WirelessHotspotsSection>
  )
}
